import React, { Component } from "react"
import { navigate } from "gatsby-link"

// Styles
import styles from "../styles/components/contact.module.scss"

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

// The date values on state are for the form only. To change functionality, edit datePicker.js
class Contact extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    emailValid: false,
    message: "",
  }

  handleChange = e => {
    if (e.target.name === "email") {
      this.validateEmail(e.target.value)
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  validateEmail = email => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.setState({
      emailValid: re.test(String(email).toLowerCase()),
    })
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <form
          className={styles.form}
          name="contact"
          method="post"
          action="/success/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <label style={{ display: "none" }}>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>

          <div className={styles.group}>
            <label htmlFor="name" className={styles.name}>
              <input
                type="text"
                name="name"
                required
                autoComplete="off"
                placeholder="Name"
                onChange={this.handleChange}
                value={this.state.name}
              />
            </label>
            <label htmlFor="phone" className={styles.phone}>
              <input
                type="tel"
                name="phone"
                required
                placeholder="Phone Number"
                onChange={this.handleChange}
                value={this.state.phone}
              />
            </label>

            <label htmlFor="email" className={styles.email}>
              <input
                type="email"
                name="email"
                required
                placeholder="Email Address"
                onChange={this.handleChange}
                value={this.state.email}
              />
            </label>

            <label htmlFor="message" className={styles.message}>
              <textarea
                type="text"
                name="message"
                required
                placeholder="Message"
                onChange={this.handleChange}
                value={this.state.message}
              />
            </label>
            <button className={styles.btn}>Submit</button>
          </div>
        </form>
      </div>
    )
  }
}

export default Contact
