import React from "react"
import { FaFacebookF, FaInstagram, FaEnvelope } from "react-icons/fa"
// Styles
import styles from "../../styles/components/navigation/social.module.scss"

export default function Social({ variant }) {
  return (
    <div className={variant === "grey" ? styles.grey : styles.social}>
      <a href="https://www.facebook.com/sunterra.lk" target="blank">
        <FaFacebookF />
      </a>
      <a href="https://www.instagram.com/sunterra.lk/" target="blank">
        <FaInstagram />
      </a>
      <a href="mailto:info@sunterra.lk" target="_top">
        <FaEnvelope />
      </a>
    </div>
  )
}
