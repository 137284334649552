import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// Components
import SEO from "../components/seo"
import Header from "../components/navigation/header"
import Layout from "../components/layout"
import Footer from "../components/footer"
import Title from "../components/title"
import Social from "../components/navigation/social"
import ContactForm from "../components/contactForm"

// Styles
import styles from "../styles/pages/contact.module.scss"

export default function Contact() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div>
      <Header />
      <SEO
        title="Contact Us"
        article={false}
        pathname="/contact"
        description="Talk to us about how you can earn and save money every month with solar!"
      />
      <section>
        <Layout>
          <Title text="Have some questions?" />
          <p className={styles.info}>
            +94 77 500 8888 / +94 70 500 8888 | 188 Buthgamuwa road, Rajagiriya,
            Sri Lanka
          </p>
          <div className={styles.socialIcons}>
            <Social variant="grey" />
          </div>

          <div className={styles.main}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              className={styles.image}
            />
            <ContactForm />
          </div>
        </Layout>
      </section>
      <Footer />
    </div>
  )
}
